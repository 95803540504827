.englishQuote{
    color: white; 
    font-family: 'Merriweather', serif; 
    text-shadow: 1px 1px black;
    padding-right: 70px;
}

.author { 
    color: white; 
    font-family: 'Helvetica Neue', sans-serif; 
    font-weight: bold; 
    line-height: 12px; 
    letter-spacing: 3px; 
    text-shadow: black 1px 1px; 
    text-transform: uppercase;
    padding-right: 70px;
}


.customRow{
    min-height: 87vh !important;
  }
  