.customGrid{
  margin-left: 0rem !important;
  margin-right: 0rem !important;
  min-height: 100vh;
  /* background-image: url("./assets/background.jpg") ; */
  background: linear-gradient( rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3) ), url('./assets/background.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;

  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.customRow{
  padding: 0rem !important;
}

.customColumn{
  padding: 0rem !important;

}