
.customMenu{
    border-radius: 0rem !important;
    background-color: rgba(255,255,255,0) !important;

}

.customMenuSelected{

    background-color: lightgray !important;
    
    
}


.login{
    left: 45%;
    position: fixed !important;
    top: 40%;
    z-index: 1000;
    box-shadow: 0 0 5px 2px  !important;
}


.customRow{
    padding: 0rem !important;
  }
  
.customColumn{
    padding: 0rem !important;
  
  }


.username{
    font-weight: bolder !important;
}